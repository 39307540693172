<template>
  <div class='flex flex-col lg:flex-row gap-y-4 lg:gap-x-8 mt-4'>
    <div class='text-2xl lg:text-3xl font-semibold uppercase w-full lg:w-1/4 pt-4'>
      Barcode
    </div>
    <div class='p-4 bg-gray-50 flex-grow border rounded-lg border-gray-100'>
      <barcode :barcode-value='barcodeValue' class='mx-auto w-full' />
      <div v-if='myPageBarcodeHelperText' 
        v-html='myPageBarcodeHelperText' 
        class='mx-auto text-center mt-4 text-sm text-gray-700'></div>
    </div>
  </div>
</template>

<script>
import Barcode from '@/components/Barcode.vue'
import { mapGetters } from 'vuex'


export default {
  name: 'MyPageBarcode',
  components: {
    Barcode,
  },
  computed: {
    ...mapGetters('events', [
      'showingEventMyPageConfigurations',
    ]),
    ...mapGetters('myPages', [
      'myPageDataProfile',
    ]),
    barcodeValue () {
      return `${this.myPageDataProfile.userId}`
    },
    myPageBarcodeHelperText () {
      return (this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageBarcodeConfigs) ? this.showingEventMyPageConfigurations.myPageBarcodeConfigs.myPageBarcodeHelperText : ''
    },
  },
}
</script>
