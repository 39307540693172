<template>
  <svg id='barcode' class='w-full'></svg>
</template>

<script>
var JsBarcode = require("jsbarcode")
export default {
  name: 'Barcode',
  components: {
  },
  props: {
    barcodeValue: {
      type: String,
      default: '0'
    },
    options: {
      type: Object,
      default: () => {
        return { }
      }
    },
  },
  watch: {
    barcodeValue: {
      handler (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            JsBarcode('#barcode', newVal, {
              format: 'CODE128',
              lineColor: '#333333',
              background: '#FAFAFA',
              width: 4,
              height: 64,
              displayValue: true
            })
          })
        }
      }, 
      immediate: true,
    },
  },
}
</script>
